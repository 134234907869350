@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Font Declarations */
@font-face {
  font-family: "PP Mori";
  src: url("./assets/fonts/PP Mori/PPMori-Extralight.otf");
  font-weight: 400;
}

@font-face {
  font-family: "PP Mori";
  src: url("./assets/fonts/PP Mori/PPMori-Regular.otf");
  font-weight: 500;
}

@font-face {
  font-family: "PP Mori";
  src: url("./assets/fonts/PP Mori/PPMori-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Tobias";
  src: url("./assets/fonts/tobias/Tobias-Thin.ttf");
}

/* Custom Background Images */
@layer utilities {
  .bg-about-banner {
    background-image: url('./assets/images/about_banner.png');
  }

  .bg-home-banner {
    background-image: url('./assets/images/home_banner.png');
  }

  .bg-intrapac-property {
    background-image: url('./assets/images/intrapac-property.png');
  }

  .bg-life-banner {
    background-image: url('./assets/images/life_banner.png');
  }

  .bg-masterplan-banner {
    background-image: url('./assets/images/masterplan_banner.png');
  }

  .bg-news-banner {
    background-image: url('./assets/images/news_banner.png');
  }

  .bg-progress-banner {
    background-image: url('./assets/images/progress_banner.png');
  }

  .bg-display-banner {
    background-image: url('./assets/images/display_banner.png');
  }

  /* Custom Gradient Classes */
  .from-lightBlack {
    --tw-gradient-from: #0000007d var(--tw-gradient-from-position);
    --tw-gradient-to: #0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .from-lightGrey {
    --tw-gradient-from: #b3b3b37d var(--tw-gradient-from-position);
    --tw-gradient-to: #b3b3b300 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
}

/* Animate.css - https://animate.style/ */
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  -webkit-animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: 2;
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  -webkit-animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: 3;
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: 2s;
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: 3s;
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: 4s;
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: 5s;
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: 0.5s;
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  -webkit-animation-duration: 0.8s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.8);
  animation-duration: 0.8s;
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
  -webkit-animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: 2s;
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  -webkit-animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: 3s;
  animation-duration: calc(var(--animate-duration) * 3);
}

@media (prefers-reduced-motion: reduce), print {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
  }

  .animated[class*="Out"] {
    opacity: 0;
  }
}

/* Animation keyframes - include only the ones you need */
@keyframes fadeIn {
  0% { opacity: 0; }
  to { opacity: 1; }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* Add other animation keyframes as needed */

/* Fix for text-size-adjust */
:host,
html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-feature-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-variation-settings: normal;
  line-height: 1.5;
  tab-size: 4;
}

/* Fix for text-decoration-line */
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.cms-entry p {
  margin-bottom: 20px;
}


/* Import animate.css separately to avoid conflicts with Tailwind */
@import 'animate.css';
@import './styles/articleStyles.css';
