/* Article Content Styling */
.article-content h1 {
  @apply font-tobias text-2xl sm:text-3xl lg:text-4xl xl:text-40 2xl:text-5xl text-gableGreen mb-4;
}

.article-content h2 {
  @apply font-tobias text-xl sm:text-2xl lg:text-3xl 2xl:text-4xl text-gableGreen mt-6 mb-3;
}

.article-content h3 {
  @apply font-tobias text-lg sm:text-xl lg:text-2xl 2xl:text-3xl text-gableGreen mt-5 mb-2;
}

.article-content p, .article-content span {
  @apply text-xs sm:text-sm xl:text-base 2xl:text-lg font-mori mt-2 xl:mt-4;
  @apply text-gableGreen !important;
}

.article-content a {
  @apply text-cuttySark hover:underline;
}

/* Additional article elements you might want to style */
.article-content ul, .article-content ol {
  @apply ml-5 mb-4 text-gableGreen;
}

.article-content li {
  @apply mb-2 text-sm lg:text-base xl:text-lg 2xl:text-xl font-mori;
}

.article-content blockquote {
  @apply pl-4 border-l-4 border-cuttySark italic my-4;
}

/* .article-content img {
  @apply max-w-full my-6;
}  */

/* Gallery Sliders */
.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
  outline: 0;
}

.flex-control-nav,
.flex-direction-nav,
.slides {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flexslider {
  padding: 0;
  margin: 0;
  background: 0 0;
  width: 100%;
  zoom: 1;
  position: relative;
  float: left;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}
.flexslider:before, .flexslider:after {
  content: "";
  display: table;
}
.flexslider:after {
  clear: both;
}
.flexslider.intrapac .slides .intrapac-slide__left, .flexslider.intrapac .slides .intrapac-slide__right {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .flexslider.intrapac .slides .intrapac-slide__left, .flexslider.intrapac .slides .intrapac-slide__right {
    width: 50%;
  }
  .flexslider.intrapac .slides .intrapac-slide__right {
    padding-left: 1.5rem;
    padding-bottom: 0;
  }
}
.flexslider.intrapac .slides .intrapac-slide__left {
  float: left;
}
.flexslider.intrapac .slides .intrapac-slide__left img {
  width: 100%;
}
.flexslider.intrapac .slides .intrapac-slide__right {
  float: right;
  padding-bottom: 30px;
}
.flexslider.intrapac .slides .intrapac-slide__right .intrapac-logo {
  display: block;
  width: 100%;
  height: 100px;
  position: relative;
  margin-bottom: 64px;
}
.flexslider.intrapac .slides .intrapac-slide__right .intrapac-logo::after {
  content: "";
  position: relative;
  width: 100%;
  height: 1px;
  margin: 30px auto 36px;
  background-color: #c3c3c3;
  display: block;
}
.flexslider.intrapac .slides .intrapac-slide__right .intrapac-logo img {
  height: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
}
.flexslider.intrapac .flex-direction-nav {
  right: 0;
  left: auto;
  top: 30px;
  width: 100%;
  padding-left: 1.5rem;
}
@media only screen and (min-width: 1024px) {
  .flexslider.intrapac .flex-direction-nav {
    width: 50%;
  }
}
.flexslider.intrapac .flex-direction-nav .flex-prev {
  left: 0;
}
@media only screen and (min-width: 1024px) {
  .flexslider.intrapac .flex-direction-nav .flex-prev {
    left: 30px;
  }
}
.flexslider.intrapac .flex-direction-nav .flex-prev {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.flexslider.intrapac .flex-direction-nav .flex-prev,
.flexslider.intrapac .flex-direction-nav .flex-next {
  background-color: #00aeb3;
  background-position: center -2679px;
}
.flexslider.intrapac .flex-direction-nav .flex-prev:hover,
.flexslider.intrapac .flex-direction-nav .flex-next:hover {
  opacity: 0.75;
}
.flexslider.home {
  height: 600px;
  background-color: #ffffff;
  z-index: 1;
}
.flexslider.home .slide-img-ctn {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 600px;
  width: 100%;
  padding: 20px 15px;
  position: relative;
}
.flexslider.home .slide-img-ctn .slide-title {
  position: absolute;
  display: block;
  background-color: #06969c;
  width: 100%;
  max-width: 450px;
  padding: 35px 40px 40px;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;
}
.flexslider.home .slide-img-ctn .slide-title img {
  width: 51px;
  height: auto;
  display: inline-block;
  margin-bottom: 18px;
}
.flexslider.home .slide-img-ctn .slide-title h4 {
  color: #ffffff;
  margin-bottom: 0;
}
.flexslider.home .slide-img-ctn .slide-title .btn {
  position: absolute;
  bottom: -20px;
  left: 50%;
  min-width: 170px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}
.flexslider.home .slides li:nth-child(2) .slide-title h4,
.flexslider.home .slides li:nth-child(2) .slide-title .btn .btnTxt {
  color: #ffd140;
}

.flexslider .slides > li:first-child {
  display: block;
  -webkit-backface-visibility: visible;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.no-js .slides > li:first-child,
.slides:after,
html[xmlns] .slides {
  display: block;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.slides:after {
  content: ".";
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

* html .slides {
  height: 1%;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

.carousel li {
  margin-right: 5px;
}

.flex-control-nav {
  display: none;
}

.slides {
  position: relative;
}

/* Direction Nav */
.flex-direction-nav {
  height: 45px;
  width: 93px;
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
}
.page-display-village .flex-direction-nav {
  bottom: 50px;
}
.page-display-village-copy .flex-direction-nav {
  bottom: 50px;
}
.home .flex-direction-nav {
  width: auto;
  height: auto;
  position: initial;
  bottom: auto;
  right: auto;
}
.home .flex-direction-nav li {
  position: absolute;
  width: 45px;
  height: 45px;
  z-index: 50;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home .flex-direction-nav li a {
  background-image: url("../assets/images/left-arrow.png");
  background-position: center;
  background-color: transparent;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .home .flex-direction-nav li a {
    background-image: url("../assets/images/left-arrow@2x.png");
    background-size: 20px 30px;
  }
}
.home .flex-direction-nav li a:hover {
  background-color: #ffffff;
  background-position: center;
  background-image: url("../assets/images/left-arrow-hover.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .home .flex-direction-nav li a:hover {
    background-image: url("../assets/images/left-arrow-hover@2x.png");
    background-size: 20px 30px;
  }
}
.home .flex-direction-nav li.flex-nav-prev {
  left: 0;
}
.home .flex-direction-nav li.flex-nav-next {
  right: 0;
}
.home .flex-direction-nav li.flex-nav-next a {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
}

.single-post .flex-direction-nav {
  left: 0;
  right: inherit;
}

.flex-direction-nav a,
.flex-navigation a {
  width: 45px;
  height: 45px;
  margin: 0;
  display: block;
  background-image: url("../assets/images/icon-sprite-new.png");
  background-color: #ffffff;
  background-position: center -2542px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: absolute;
  top: 0;
  cursor: pointer;
  text-indent: -9999px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .flex-direction-nav a,
.flex-navigation a {
    background-image: url("../assets/images/icon-sprite-new@2x.png");
    background-size: 268px 3200px;
  }
}
.flex-direction-nav a:hover,
.flex-navigation a:hover {
  background-color: #00aeb3;
  background-position: center -2588px;
}

.flex-direction-nav a.flex-next,
.flex-navigation a.flex-next {
  background-position: center -2634px;
  right: 0;
}
.flex-direction-nav a.flex-next:hover,
.flex-navigation a.flex-next:hover {
  background-color: #00aeb3;
  background-position: center -2679px;
}

.flex-direction-nav .flex-prev,
.flex-navigation .flex-prev {
  left: 0;
}

.flex-direction-nav .flex-disabled,
.flex-navigation .flex-disabled {
  display: none;
  cursor: default;
}

.flex-navigation {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 14px;
  margin-bottom: 28px;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
  z-index: 2;
}
.flex-navigation a.flex-prev,
.flex-navigation a.flex-next {
  top: 12px;
  background-color: #00aeb3;
  background-position: center -2588px;
}
.flex-navigation a.flex-prev:hover,
.flex-navigation a.flex-next:hover {
  background-color: #00dce2;
}
.flex-navigation a.flex-next {
  background-position: center -2679px;
}
.flex-navigation .project_logo {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  width: 140px;
  height: 65px;
}

p[class^=flex-caption] {
  clear: both;
  padding-top: 5px;
  font-size: 16px;
}
.content-ctn p[class^=flex-caption] {
  margin-bottom: 30px;
}

.gallery_slider_ctn {
  margin-bottom: 100px;
}

.gallery_slider_descr {
  text-align: left;
  padding-right: 4%;
  position: relative;
  z-index: 5;
}
.gallery_slider_descr.right {
  padding-right: 0;
  padding-left: 4%;
}
.gallery_slider_descr .btn.stage_donwload_pdf {
  margin-top: 28px;
}

.projects-slider-ctn {
  margin-top: 70px;
}

.projects-slider {
  position: relative;
  text-align: left;
}
.projects-slider h4 {
  color: #00aeb3;
  margin-bottom: 5px;
}
.page-display-village .projects-slider h4 {
  font-size: 32px;
  line-height: 56px;
  letter-spacing: 1.75px;
}
.page-display-village-copy .projects-slider h4 {
  font-size: 32px;
  line-height: 56px;
  letter-spacing: 1.75px;
}
.projects-slider h6 {
  color: #6d6e71;
}